import { CommonModule } from "@angular/common";
import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import { iconLib } from "@app/core/const/roam-icon";
import { IAssociation } from "@app/shared/interfaces";
import { InlineSVGModule } from "ng-inline-svg-2";
import { distinctUntilChanged, merge, Subject, takeUntil, tap } from "rxjs";
import { RoamButtonComponent } from "../../button/roam-button/roam-button.component";
import { AuthStore } from "@app/core/auth";
import { UserConfigStore } from "@app/core/user-config/+data-access";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RoamButtonComponent,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    InlineSVGModule,
    MatButtonModule,
  ],
  selector: "app-dialog-select-association",
  templateUrl: "./dialog-association.component.html",
  styleUrls: ["./dialog-association.component.scss"],
})
export class DialogSelectAssociationComponent implements OnInit {
  public icon = iconLib;
  #destroy = new Subject<void>();
  #auth = inject(AuthStore);
  readonly userConfig = inject(UserConfigStore);

  readonly user = this.#auth.getAuthUser();
  readonly dialogRef = inject(MatDialogRef<DialogSelectAssociationComponent>);

  next() {
    const selectedKey = this.state.selectedAssociationKey();
    this.userConfig.selectAssociation(this.state.selectedAssociationKey());
    this.userConfig.selectManager(this.state.selectedManagerKey());
    this.dialogRef.close(selectedKey);
  }

  close() {
    this.dialogRef.close(null);
  }

  readonly state = {
    keyword: signal(""),
    associations: signal<IAssociation[]>([]),
    selectedManagerKey: signal<string | null>(null),
    selectedAssociationKey: signal<string | "">(""),
  };

  protected displayedAssociations = computed(() => {
    const keyword = this.state.keyword();
    return this.state
      .associations()
      .filter(x => x.name?.toLowerCase().includes(keyword.toLowerCase()));
  });

  protected selectedAssociation = computed(() => {
    const key = this.state.selectedAssociationKey();
    return this.state.associations().find(v => v.id === key);
  });

  constructor() {
    this.state.associations.set(this.userConfig.getAssociations());
  }

  ngOnInit(): void {
    merge(
      this.userConfig.selectedAssociation$.pipe(
        distinctUntilChanged(),
        tap(association => {
          this.state.selectedAssociationKey.set(association?.id || '');
        })
      )
    )
      .pipe(takeUntil(this.#destroy))
      .subscribe();
  }

  selectAssociation(e: MatSelectChange): void {
    const managerId = this.state.associations().find(x => x.id === e.value)?.managerId || null;
    this.state.selectedAssociationKey.set(e.value);
    this.state.selectedManagerKey.set(managerId);
  }

  associationCompareFn(association: any, association2: any): boolean {
    return association.name === association2.name && association.id === association2.id;
  }
}
